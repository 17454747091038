import {DropDownLabelValue} from '@/app/ui/components/DropdownSelect/interface'

export const LIST_PERIOD_ALL_CUSTOMER_OPTIONS: DropDownLabelValue<string>[] = [
  {
    label: 'Daily',
    value: 'DAILY',
  },
  {
    label: 'Weekly',
    value: 'WEEKLY',
  },
  {
    label: 'Monthly',
    value: 'MONTHLY',
  },
]

export enum EnumTypeCustomer {
  PER_CUSTOMER = 'PER_CUSTOMER',
  SPECIFIC_CUSTOMER = 'SPECIFIC_CUSTOMER',
}

export const TYPE_CUSTOMER_OPTIONS: DropDownLabelValue<EnumTypeCustomer>[] = [
  {
    label: 'Per Customer',
    value: EnumTypeCustomer.PER_CUSTOMER,
  },
  {
    label: 'Specific Customer',
    value: EnumTypeCustomer.SPECIFIC_CUSTOMER,
  },
]

