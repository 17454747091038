



















































































































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator'
import VoucherTextInput from '@/app/ui/views/Voucher/components/VoucherTextInput/index.vue'
import {maxLength, minLength, minValue, required, requiredIf, ValidationRule} from 'vuelidate/lib/validators'
import {Validations} from 'vuelidate-property-decorators'
import {validationMixin} from 'vuelidate'
import Button from '@/app/ui/components/Button/index.vue'
import ModalAction from '@/app/ui/components/Modal/ModalAction.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import VoucherDropdown from '@/app/ui/views/Voucher/components/VoucherDropdown/index.vue'
import {DropDownLabelValue} from '@/app/ui/components/DropdownSelect/interface'
import MultiselectV2 from '@/app/ui/components/MultiselectV2/index.vue'
import {Utils} from '@/app/infrastructures/misc'
import * as constantData from '@/app/infrastructures/misc/Constants/voucherConfig'
import {LabelValue} from '@/app/ui/components/OptionBox/interfaces'
import VoucherCheckbox from '@/app/ui/views/Voucher/components/VoucherCheckbox/index.vue'
import VoucherMultiTagTextInput from '@/app/ui/views/Voucher/components/VoucherMultiTagInput/index.vue'
import ModalUploadBulkySpecificCustomer
  from '@/app/ui/views/Voucher/components/ModalUploadBulky/UploadBulkySpecificCustomer.vue'
import PreviewUploadBulkModal
  from '@/app/ui/views/Voucher/components/PreviewUploadBulky/PreviewUploadBulkySpecificCustomerModal.vue'
import {EnumStatusUpload} from '@/app/infrastructures/misc/Constants/upload'
import controller from '@/app/ui/controllers/VoucherConfigSetLimitController'
import controllerSKU from '@/app/ui/controllers/VoucherConfigSKUController'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import {DataObject, HeaderObject} from '@/app/ui/components/DataTableV2/type'
import {VoucherConfigSetLimitPayloadInterface} from '@/data/payload/api/VoucherConfigSetLimitRequest'
import LoadingOverlay from "@/app/ui/components/LoadingOverlay/index.vue";
import {VoucherSetLimit} from "@/domain/entities/VoucherConfigSetLimit";

interface validationInterface {
  form: {
    rulesName: {
      required: () => ValidationRule,
      minLength: ValidationRule,
      maxLength: ValidationRule,
    },
    sku: {
      required: () => ValidationRule,
    },
    periodAllCustomer: {
      requiredIf: ValidationRule
    },
    typeCustomer: {
      requiredIf: ValidationRule
    },
    periodTypeCustomer: {
      requiredIf: ValidationRule
    },
    qtySKUPeriodAllCustomer: {
      requiredIf: ValidationRule
      minValue: ValidationRule | boolean
    },
    qtySKUPeriodTypeCustomer: {
      requiredIf: ValidationRule
      minValue: ValidationRule | boolean
    },
    amountVoucherPeriodAllCustomer: {
      requiredIf: ValidationRule
      minValue: ValidationRule | boolean
    },
    amountVoucherPeriodTypeCustomer: {
      requiredIf: ValidationRule
      minValue: ValidationRule | boolean
    },
    customerName: {
      requiredIf: ValidationRule
    },
  }
}

@Component({
  mixins: [validationMixin],
  components: {
    LoadingOverlay,
    ModalUploadBulkySpecificCustomer,
    PreviewUploadBulkModal,
    MultiselectV2,
    VoucherDropdown,
    Modal,
    ModalAction,
    Button,
    VoucherTextInput,
    VoucherCheckbox,
    VoucherMultiTagTextInput,
    DataTableV2,
  }
})
export default class VoucherAndSKUCreate extends Vue {
  controller = controller
  controllerSKU = controllerSKU
  maxCount = 30
  isFetchSKULoading = false
  constants = constantData

  tagChanged: {
    customerName: boolean,
  } = {
    customerName: false,
  }

  selection: {
    isQuantitySKUPeriodAllCustomerChecked: boolean,
    isQuantitySKUPeriodTypeCustomerChecked: boolean,
    isAmountVoucherPeriodAllCustomerChecked: boolean,
    isAmountVoucherPeriodTypeCustomerChecked: boolean,
  } = {
    isQuantitySKUPeriodAllCustomerChecked: false,
    isQuantitySKUPeriodTypeCustomerChecked: false,
    isAmountVoucherPeriodAllCustomerChecked: false,
    isAmountVoucherPeriodTypeCustomerChecked: false,
  }

  form: {
    rulesName: string,
    sku: LabelValue<number>[],
    periodAllCustomer: LabelValue<string> | null,
    typeCustomer: LabelValue<constantData.EnumTypeCustomer> | null,
    periodTypeCustomer: LabelValue<string> | null,
    qtySKUPeriodAllCustomer: number,
    qtySKUPeriodTypeCustomer: number,
    amountVoucherPeriodAllCustomer: number,
    amountVoucherPeriodTypeCustomer: number,
    customerName: number[],
  } = {
    rulesName: '',
    sku: [],
    periodAllCustomer: null,
    typeCustomer: null,
    periodTypeCustomer: null,
    qtySKUPeriodAllCustomer: 0,
    qtySKUPeriodTypeCustomer: 0,
    amountVoucherPeriodAllCustomer: 0,
    amountVoucherPeriodTypeCustomer: 0,
    customerName: [],
  }

  showUploadModal = false
  showModalConfirm = false
  showModalSuccess = false
  modalActionData = {
    title: '',
    description: '',
    textPrimary: '',
    textSecondary: '',
  }
  showPreviewUploadModalSpecificCustomer = false
  headersTable: HeaderObject[] = [
    {
      title: 'No',
    },
    {
      title: 'Customer ID',
    }
  ]
  dataItemsTable: DataObject[] = []

  @Validations()
  validations(): validationInterface {
    return {
      form: {
        rulesName: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(this.maxCount),
        },
        sku: {
          required,
        },
        periodAllCustomer: {
          requiredIf: requiredIf(() => this.form.typeCustomer === null)
        },
        typeCustomer: {
          requiredIf: requiredIf(() => this.form.periodAllCustomer === null)
        },
        periodTypeCustomer: {
          requiredIf: requiredIf(
            () => this.isPeriodTypePerCustomer && this.form.typeCustomer !== null,
          ),
        },
        qtySKUPeriodAllCustomer: {
          requiredIf: requiredIf(
            () => this.selection.isQuantitySKUPeriodAllCustomerChecked,
          ),
          minValue: this.selection.isQuantitySKUPeriodAllCustomerChecked ? minValue(1) : true,
        },
        qtySKUPeriodTypeCustomer: {
          requiredIf: requiredIf(
            () => this.selection.isQuantitySKUPeriodTypeCustomerChecked,
          ),
          minValue: this.selection.isQuantitySKUPeriodTypeCustomerChecked ? minValue(1) : true,
        },
        customerName: {
          requiredIf: requiredIf(
            () => this.form.typeCustomer && this.form.typeCustomer.value === constantData.EnumTypeCustomer.SPECIFIC_CUSTOMER,
          ),
        },
        amountVoucherPeriodAllCustomer: {
          requiredIf: requiredIf(
            () => this.selection.isAmountVoucherPeriodAllCustomerChecked,
          ),
          minValue: this.selection.isAmountVoucherPeriodAllCustomerChecked ? minValue(1) : true,
        },
        amountVoucherPeriodTypeCustomer: {
          requiredIf: requiredIf(
            () => this.selection.isAmountVoucherPeriodTypeCustomerChecked,
          ),
          minValue: this.selection.isAmountVoucherPeriodTypeCustomerChecked ? minValue(1) : true,
        },
      }
    }
  }

  created(): void {
    controllerSKU.getAll({
      sort: 'ASC',
      filterBy: undefined,
      page: 1,
      perPage: 20,
      keyword: ''
    })
    controller.getOne(+this.$route.params.id)
  }

  get targetSKUList(): DropDownLabelValue<number>[] {
    return controllerSKU.listData.map((item) => {
      const currency = Utils.toRupiah(item.voucherAmount)
      return {
        label: `<div>${item.skuCode} <span class="text-gray-1"> | </span> ${item.voucherName} ${currency}</div> `,
        value: item.id,
        title: item.voucherName,
      }
    })
  }

  @Watch('controllerSKU.isLoading')
  onLoadingSKUChange(isLoading: boolean): void {
    this.isFetchSKULoading = isLoading
  }

  private skuSearch = Utils.debounce((search: string): void => {
    controllerSKU.getAll({
      sort: 'ASC',
      filterBy: undefined,
      page: 1,
      perPage: 20,
      keyword: search
    })
  }, 400)

  get labelTypePeriodCustomer(): string {
    return this.form.typeCustomer && this.form.typeCustomer.value === constantData.EnumTypeCustomer.PER_CUSTOMER
      ? 'Period Per Customer'
      : 'Period Specific Customer'
  }

  get isPeriodTypePerCustomer(): boolean {
    return this.form.typeCustomer !== null && this.form.typeCustomer.value === constantData.EnumTypeCustomer.PER_CUSTOMER
  }

  private onPeriodAllCustomerChange(): void {
    this.form.qtySKUPeriodAllCustomer = 0
    this.selection.isQuantitySKUPeriodAllCustomerChecked = false
    this.form.amountVoucherPeriodAllCustomer = 0
    this.selection.isAmountVoucherPeriodAllCustomerChecked = false
  }

  private onPeriodTypeCustomerChange(): void {
    this.form.qtySKUPeriodTypeCustomer = 0
    this.selection.isQuantitySKUPeriodTypeCustomerChecked = false
    this.form.amountVoucherPeriodTypeCustomer = 0
    this.selection.isAmountVoucherPeriodTypeCustomerChecked = false
  }

  private onQuantitySKUPeriodAllCustomerToggle(): void {
    this.selection.isQuantitySKUPeriodAllCustomerChecked = !this.selection.isQuantitySKUPeriodAllCustomerChecked
    if (!this.selection.isQuantitySKUPeriodAllCustomerChecked) {
      this.form.qtySKUPeriodAllCustomer = 0
    }
  }

  private onQuantitySKUPeriodTypeCustomerToggle(): void {
    this.selection.isQuantitySKUPeriodTypeCustomerChecked = !this.selection.isQuantitySKUPeriodTypeCustomerChecked
    if (!this.selection.isQuantitySKUPeriodTypeCustomerChecked) {
      this.form.qtySKUPeriodTypeCustomer = 0
    }
  }

  private onAmountVoucherPeriodAllCustomerToggle(): void {
    this.selection.isAmountVoucherPeriodAllCustomerChecked = !this.selection.isAmountVoucherPeriodAllCustomerChecked
    if (!this.selection.isAmountVoucherPeriodAllCustomerChecked) {
      this.form.amountVoucherPeriodAllCustomer = 0
    }
  }

  private onAmountVoucherPeriodTypeCustomerToggle(): void {
    this.selection.isAmountVoucherPeriodTypeCustomerChecked = !this.selection.isAmountVoucherPeriodTypeCustomerChecked
    if (!this.selection.isAmountVoucherPeriodTypeCustomerChecked) {
      this.form.amountVoucherPeriodTypeCustomer = 0
    }
  }

  private onTypeCustomerChange(): void {
    this.form.periodTypeCustomer = null
    this.onPeriodTypeCustomerChange()
  }

  get isSKUAndAmountUnselectedOnAllCustomer(): boolean {
    return !this.selection.isQuantitySKUPeriodAllCustomerChecked && !this.selection.isAmountVoucherPeriodAllCustomerChecked
  }

  get isSKUAndAmountUnselectedOnTypeCustomer(): boolean {
    return !this.selection.isQuantitySKUPeriodTypeCustomerChecked && !this.selection.isAmountVoucherPeriodTypeCustomerChecked
  }

  get isAnyInvalidOnTypeCustomerOrAllCustomer(): boolean {
    if (this.form.periodAllCustomer !== null && !this.isSKUAndAmountUnselectedOnAllCustomer && this.form.typeCustomer !== null && this.isSKUAndAmountUnselectedOnTypeCustomer) {
      return true
    }

    return this.form.periodAllCustomer !== null && this.isSKUAndAmountUnselectedOnAllCustomer || this.form.typeCustomer !== null && this.isSKUAndAmountUnselectedOnTypeCustomer;
  }

  get isPeriodDataAllCustomerValid(): boolean {
    if (this.form.periodAllCustomer && this.selection.isQuantitySKUPeriodAllCustomerChecked && !this.selection.isAmountVoucherPeriodAllCustomerChecked) {
      return this.$v.form.qtySKUPeriodAllCustomer !== undefined && this.$v.form.qtySKUPeriodAllCustomer.$model !== '' && !this.$v.form.qtySKUPeriodAllCustomer.$invalid
    }
    if (this.form.periodAllCustomer && !this.selection.isQuantitySKUPeriodAllCustomerChecked && this.selection.isAmountVoucherPeriodAllCustomerChecked) {
      return this.$v.form.amountVoucherPeriodAllCustomer !== undefined && +this.form.amountVoucherPeriodAllCustomer > 0 && !this.$v.form.amountVoucherPeriodAllCustomer.$invalid
    }
    if (this.form.periodAllCustomer && this.selection.isQuantitySKUPeriodAllCustomerChecked && this.selection.isAmountVoucherPeriodAllCustomerChecked) {
      const isValidQty = this.$v.form.qtySKUPeriodAllCustomer !== undefined && this.$v.form.qtySKUPeriodAllCustomer.$model !== '' && this.$v.form.qtySKUPeriodAllCustomer.$model !== '' && !this.$v.form.qtySKUPeriodAllCustomer.$invalid
      const isValidAmount = this.$v.form.amountVoucherPeriodAllCustomer !== undefined && +this.form.amountVoucherPeriodAllCustomer > 0 && !this.$v.form.amountVoucherPeriodAllCustomer.$invalid
      return isValidQty && isValidAmount
    }
    return false
  }

  get isPeriodTypeCustomerValid(): boolean {
    if (this.form.periodTypeCustomer && this.selection.isQuantitySKUPeriodTypeCustomerChecked && !this.selection.isAmountVoucherPeriodTypeCustomerChecked) {
      return this.$v.form.qtySKUPeriodTypeCustomer !== undefined && +this.form.qtySKUPeriodTypeCustomer > 0 && !this.$v.form.qtySKUPeriodTypeCustomer.$invalid
    }
    if (this.form.periodTypeCustomer && !this.selection.isQuantitySKUPeriodTypeCustomerChecked && this.selection.isAmountVoucherPeriodTypeCustomerChecked) {
      return this.$v.form.amountVoucherPeriodTypeCustomer !== undefined && +this.form.amountVoucherPeriodTypeCustomer > 0 && !this.$v.form.amountVoucherPeriodTypeCustomer.$invalid
    }
    if (this.form.periodTypeCustomer && this.selection.isQuantitySKUPeriodTypeCustomerChecked && this.selection.isAmountVoucherPeriodTypeCustomerChecked) {
      const isValidQty = this.$v.form.qtySKUPeriodTypeCustomer !== undefined && +this.form.qtySKUPeriodTypeCustomer > 0 && !this.$v.form.qtySKUPeriodTypeCustomer.$invalid
      const isValidAmount = this.$v.form.amountVoucherPeriodTypeCustomer !== undefined && +this.form.amountVoucherPeriodTypeCustomer > 0 && !this.$v.form.amountVoucherPeriodTypeCustomer.$invalid
      return isValidQty && isValidAmount
    }
    return false
  }

  actionShowConfirm(): void {
    this.showModalConfirm = true
    this.modalActionData = {
      title: `Are you sure want to edit SKU limit: ${this.form.rulesName} ?`,
      description: '',
      textPrimary: 'Yes, Update',
      textSecondary: 'Check Again',
    }
  }

  get validForm(): boolean {
    return this.$v.form.$invalid || (!this.isPeriodDataAllCustomerValid && !this.isPeriodTypeCustomerValid) || (this.isSKUAndAmountUnselectedOnAllCustomer && this.isSKUAndAmountUnselectedOnTypeCustomer) || this.isAnyInvalidOnTypeCustomerOrAllCustomer
  }

  doActionModal(): void {
    this.showModalConfirm = false
    const payload: VoucherConfigSetLimitPayloadInterface = {
      rulesName: this.form.rulesName,
      skuGroups: this.form.sku.map((item) => +item.value),
    }
    if (this.form.typeCustomer && this.form.typeCustomer.value === constantData.EnumTypeCustomer.SPECIFIC_CUSTOMER) {
      payload.specificCustomers = this.form.customerName
    }

    if (this.form.periodAllCustomer) {
      payload.periodAllCustomer = this.form.periodAllCustomer.value
      if (this.selection.isQuantitySKUPeriodAllCustomerChecked) {
        payload.maxQuantityAllCustomer = this.form.qtySKUPeriodAllCustomer
      }
      if (this.selection.isAmountVoucherPeriodAllCustomerChecked) {
        payload.maxAmountAllCustomer = this.form.amountVoucherPeriodAllCustomer
      }
    }

    if (this.form.typeCustomer && this.form.periodTypeCustomer) {
      payload.target = this.form.typeCustomer.value
      payload.period = this.form.periodTypeCustomer.value
      if (this.selection.isQuantitySKUPeriodTypeCustomerChecked) {
        payload.maxQuantity = this.form.qtySKUPeriodTypeCustomer
      }
      if (this.selection.isAmountVoucherPeriodTypeCustomerChecked) {
        payload.maxAmount = this.form.amountVoucherPeriodTypeCustomer
      }
    }

    controller.update({data: payload, id: +this.$route.params.id})
  }

  @Watch('controller.detailData')
  onDetailDataChange(data: VoucherSetLimit): void {
    this.form.rulesName = data.ruleName
    this.form.sku = data.skuGroups.map((item) => {
      return {
        label: item.sku,
        value: item.id,
      }
    })
    this.form.periodAllCustomer = data.periodAllCustomer
      ? {
        label: data.periodAllCustomer,
        value: data.periodAllCustomer,
      }
      : null
    // this.form.typeCustomer = data.target
    //   ? {
    //     label: data.target === constantData.EnumTypeCustomer.PER_CUSTOMER ? 'Per Customer' : 'Specific Customer',
    //     value: data.target,
    //   }
    //   : null
    if (data.target && data.target !== '') {
      this.form.typeCustomer = {
        label: data.target === constantData.EnumTypeCustomer.PER_CUSTOMER ? 'Per Customer' : 'Specific Customer',
        value: data.target === constantData.EnumTypeCustomer.PER_CUSTOMER ? constantData.EnumTypeCustomer.PER_CUSTOMER : constantData.EnumTypeCustomer.SPECIFIC_CUSTOMER,
      }
    }
    this.form.periodTypeCustomer = data.period
      ? {
        label: data.period,
        value: data.period,
      }
      : null
    if (data.maxQtyAllCustomer) {
      this.selection.isQuantitySKUPeriodAllCustomerChecked = true
    }
    if (data.maxAmountAllCustomer) {
      this.selection.isAmountVoucherPeriodAllCustomerChecked = true
    }
    if (data.maxQty) {
      this.selection.isQuantitySKUPeriodTypeCustomerChecked = true
    }
    if (data.maxAmount) {
      this.selection.isAmountVoucherPeriodTypeCustomerChecked = true
    }
    this.form.qtySKUPeriodAllCustomer = data.maxQtyAllCustomer || 0
    this.form.qtySKUPeriodTypeCustomer = data.maxQty || 0
    this.form.amountVoucherPeriodAllCustomer = data.maxAmountAllCustomer || 0
    this.form.amountVoucherPeriodTypeCustomer = data.maxAmount || 0
    this.form.customerName = data.specificCustomers?.map((item) => +item) || []
  }

  @Watch('controller.isSave')
  onStatusSaveCompleted(status: boolean): void {
    if (status) {
      this.showModalSuccess = true
    }
  }

  onCloseSuccessModal(): void {
    this.$router.push('/voucher/voucher-config?tab=set-limit')
  }

  private onDownloadTemplate(): void {
    window.open(
      'https://docs.google.com/spreadsheets/d/1T647qjauhG2DcNiZX_kPkznMly8wA_2uJT9LN6DpwlY/edit#gid=0'
    )
  }

  @Watch('controller.statusUploadBulky')
  onStatusUploadBulkyCompleted(status: string | null): void {
    if (status === EnumStatusUpload.COMPLETE && !controller.forceStart) {
      this.showUploadModal = false
      this.showPreviewUploadModalSpecificCustomer = true
    }
  }

  private closePreview(): void {
    controller.setStatusUpload(EnumStatusUpload.START)
    this.showPreviewUploadModalSpecificCustomer = false
  }

  private reupload(): void {
    controller.setStatusUpload(EnumStatusUpload.START)
    this.showPreviewUploadModalSpecificCustomer = false
    this.showUploadModal = true
  }

  private retrieveDataUploadSpecificCustomer(data: number[]): void {
    this.showPreviewUploadModalSpecificCustomer = false
    this.form.customerName = data
  }

}
