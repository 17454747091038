








































import {Component, Vue, Prop} from 'vue-property-decorator'

@Component({})
export default class CardUploadResult extends Vue {
  @Prop({required: false, default: 'File Name'}) private titleFile!: string
  @Prop({required: true, default: 'fillMe.xlsx'}) private valueFileName!: string
  @Prop({required: true, default: -1}) private valueValidRows!: number
  @Prop({required: true, default: -1}) private valueInvalidRows!: number
  @Prop({required: false, default: 'Total Valid Voucher'}) private titleTotalValidOfFeature!: string
  @Prop({required: true, default: -1}) private valueTotalValidFeature!: number
  @Prop({required: false, default: 'Total Invalid Voucher'}) private titleTotalInvalidOfFeature!: string
  @Prop({required: true, default: -1}) private valueTotalInvalidFeature!: number
}
