
















import { Component, Vue, Prop } from 'vue-property-decorator'
import CheckBoxCheckedIcon from '@/app/ui/assets/check_box_checked.vue'
import CheckBoxUncheckedIcon from '@/app/ui/assets/check_box_unchecked.vue'
@Component({
  components: {
    CheckBoxCheckedIcon,
    CheckBoxUncheckedIcon,
  },
})
export default class VoucherheckBox extends Vue {
  @Prop({ default: false }) private isChecked!: boolean
  private onClickCheckBox() {
    this.$emit('click')
  }
}
